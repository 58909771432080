// import { Redirect, history } from 'umi'
import Eruda from '@common/helpers/Eruda'
import { isIOS } from '@common/helpers/is'
import { prerenderDynamics } from '@/__prefix__/prerenders'

Eruda()
export default function () {
  // 用户进入PC页时前端判断视窗宽度，如果小于768（ipad mini），则自动跳转到H5
  // H5 不需要判断视宽跳转了
  // if (window.innerWidth > 768) {
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: '/pc' + history.location.pathname,
  //         search: history.location.search,
  //       }}
  //     ></Redirect>
  //   )
  // }
  // 初始化在 pages/document.ejs 即在html内会根据url判断好是否使用新的ui
  if (window.__use_new_ui__) {
    const NewApp = prerenderDynamics.new.App
    return <NewApp></NewApp>
  }
  const OldApp = prerenderDynamics.old.App
  return <OldApp></OldApp>
}

//#region ios 移动端手机键盘导致页面内容留白不下滑
/**
 * 这里不需要移除事件监听，在ios下都是有问题的
 * 相关文档：
 * @see https://wsa.jianshu.io/p/428a5465b4db
 */
if (isIOS()) {
  document.addEventListener(
    'blur',
    (e) => {
      // 这里加了个类型判断，因为a等元素也会触发blur事件
      ;['input', 'textarea'].includes(e.target.localName) &&
        document.body.scrollIntoView(false)
    },
    true,
  )
}
//#endregion

// 开启https后 在移动端访问需要手动设置c-token才能登录
// 有需要调试用就把下面的注释去掉
// if (process.env.NODE_ENV === 'development') {
//   const JsCookie = require('js-cookie')
//   JsCookie.set('c-token', 'f6df000e-e1c2-45d1-a4a2-13d6312a8b7f', {
//     domain: '10.0.197.85',
//   })
// }
