import { run, get } from '../try'
import { isArray, isExist } from '../is'

/* Impure Function */
export const hot = (module) => (Component) => Component

export const curry =
  (fn) =>
  (...args) =>
    args.length < fn.length
      ? curry(fn.bind(undefined, ...args))
      : fn.apply(undefined, args)

export const flatten = (array) =>
  array.reduce(
    (res, item) => [...res, ...(isArray(item) ? flatten(item) : [item])],
    [],
  )

export const __ =
  (fn, context) =>
  (...preArgs) =>
    function (...args) {
      return fn.apply(
        context || this,
        preArgs.map((pr) => (pr === __ ? args.shift() : pr)).concat(args),
      )
    }

export const getFormatter =
  ({ separator = ' ', length = 3 }) =>
  (text) => {
    text = typeof text === 'number' ? Math.floor(text) : text
    let postfix = String(text).match(/(\w+)(\.\w+)$/)
      ? String(text).match(/(\w+)(\.\w+)$/)[2]
      : ''
    let prefix = String(text).match(/(\w+)(\.\w+)$/)
      ? String(text).match(/(\w+)(\.\w+)$/)[1]
      : text

    //下面也可以用正则写哦
    return typeof prefix !== 'undefined'
      ? String(prefix)
          .split('')
          .reverse()
          .reduce(
            (result, letter, index) => (
              result.unshift(
                letter,
                index > 0 && index % length === 0 ? separator : undefined,
              ),
              result
            ),
            [],
          )
          .join('') + postfix
      : text
  }

/**
 * [钳子] 用来将数字限制在给定范围内
 * @param {Number} value 被限制值
 * @param {Number} min 最小值
 * @param {Number} max 最大值
 */
export const clamp = (value, min, max) => {
  if (value < min) {
    return min
  }

  if (value > max) {
    return max
  }

  return value
}

/**
 * [防抖]
 * @param {Function} func 执行函数
 * @param {Number} wait 多少毫秒后运行一次
 */
export const debounce = (func, wait = 16) => {
  let timeout

  return (...args) => {
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      func(...args)
    }, wait)

    return timeout
  }
}

/**
 * [节流]
 * @param {Function} func 执行函数
 * @param {Number} wait 多少毫秒内运行一次
 */
export const throttle = (func, wait = 16) => {
  let locking = false

  const release = () => {
    locking = false
  }

  const debounced = debounce(func, wait)

  return (...args) => {
    debounced(...args) // 确保最后一次会执行

    if (locking) {
      return
    }

    locking = true
    func(...args)
    setTimeout(release, wait)
  }
}
