import { getRuntimeEnv } from '@common/helpers'

let inited = false

/**
 * 内部会判断 正式环境不开启
 * 其他环境开启
 */
export default function Eruda() {
  if (
    inited ||
    getRuntimeEnv() === 'prod' ||
    location.host.includes('localhost') ||
    location.href.includes('debug=0')
  ) {
    return
  }
  inited = true
  try {
    let script = document.createElement('script')
    script.src = '//cdn.jsdelivr.net/npm/eruda'
    document.body.appendChild(script)
    script.onload = function () {
      // eslint-disable-next-line no-undef
      eruda.init()
    }
    script.onerror = function (e) {
      console.error('eruda init error', e)
      inited = false
    }
  } catch (err) {
    console.error('[eruda] 初始化失败', err)
    inited = false
  }
}
