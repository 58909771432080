import axios from 'axios'
import JsCookie from 'js-cookie'
import {
  isExist,
  isIOS,
  getRuntimeEnv,
  getBizEnv,
  value,
  get,
  URL,
  storage,
} from '@common/helpers'
import _ from 'lodash'

// 这里引入store ，在h5端是没问题的，但是在pc端是会报错的，具体原因没排查到，有兴趣的可以看看
// import { store } from '../../project/openPay @/store'

//默认配置，项目可以自行添加新的配置去覆盖
axios.defaults.transformRequest = [
  (param, headers) => {
    switch (headers['Content-Type']) {
      case 'application/x-www-form-urlencoded':
        return isExist(param)
          ? Object.entries(param)
              .filter(([key, val]) => isExist(val))
              .map(
                ([key, val]) =>
                  `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
              )
              .join('&')
          : param
      case 'application/json':
        return JSON.stringify(param)
      case 'multipart/form-data':
        let formData = new FormData()
        for (let key in param) {
          formData.append(key, param[key])
        }
        return formData
      default:
        return param
    }
  },
]

axios.defaults.withCredentials = true

axios.defaults.headers = Object.entries({
  dt: value(() => {
    switch (isIOS()) {
      case true:
        return 5 // !process.env.TEST ? 2 : 3
      case false:
        return !process.env.TEST ? 1 : 4
    }
  }),
  vc: value(() => {
    switch (isIOS()) {
      case true:
        return !process.env.TEST ? 31 : 27
      case false:
        return !process.env.TEST ? undefined : 130
    }
  }),
  // 'device-type': 'browser',
  'Content-Type': 'application/x-www-form-urlencoded',
})
  // 过滤掉无效的 header
  .filter(([key, value]) => isExist(value))
  .reduce(
    (res, [key, value]) =>
      Object.assign(axios.defaults.headers, res, {
        [key]: value,
      }),
    {},
  )

// 添加请求拦截器
axios.interceptors.request.use(
  async function (config) {
    const whiteList = [
      'amazonaws',
      'risk.akulaku',
      'ec-api.akulaku.com/aapi/time/now', // 对时接口
    ] //'/risk',

    let flag = whiteList.some((item) => {
      return config.url.includes(item)
    })

    if (flag) return config

    // console.error('#####发起请求####')

    // TODO HACK 历史未严格控制时序
    // TODO 逻辑上，此处应在 getBasicInfo 后执行
    // TODO 时序飘忽，强行检查 i18n 是否已确认，超时时 reject
    await waitI18nInit() // eslint-disable-line no-use-before-define

    // 推导请求网关地址
    const countryCode = window['i18n-countryCode-zKJHiTMC2iGnHOwx']
    if (!countryCode) {
      throw new Error('代码时序错误，Ajax 确认 i18n 之后执行')
    }

    // let shouldIgnorePrefix = config.url.indexOf('/capi') > -1
    let prefix = config.url.match(/^\/(.+?)\//)?.[1]

    const gatewayInfo = computeGatewayInfo(countryCode, prefix)
    const isLocalEnv = getRuntimeEnv() === 'local'
    if (isLocalEnv) {
      config.headers = Object.assign(config.headers, {
        'x-al-gateway': gatewayInfo.gatewayOrigin,
      })
    }

    // 测试环境更换域名
    const replaceHosts = ['test-id-app.akulaku.com', 'test-ec-api.akulaku.com'] // 需要替换的域名
    let baseUrl = gatewayInfo.requestBaseUrl
    replaceHosts.forEach((host) => {
      if (baseUrl.includes(host)) {
        baseUrl = baseUrl.replace(host, 'test-pay.akulaku.com')
      }
    })

    config.url = `${baseUrl}${config.url}`

    /**
     * /capi/openpay/* 接口需要请求头
     */
    if (config.url.indexOf('/capi/openpay') > -1) {
      // 请求头信息
      const headerConfig = getRequestHeaderConfig()
      Object.keys(headerConfig).forEach((key) => {
        if (notEmpty(headerConfig[key])) {
          config.headers = Object.assign(config.headers, {
            [key]: headerConfig[key],
          })
        }
      })
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (data) => data,
  (error) => {
    // 手动上报接口错误
    reportNetworkError(error)
    return Promise.reject(error)
  },
)

async function reportNetworkError(error) {
  const Sentry = await import('@sentry/browser')
  //未登录接口状态过滤
  const ERROR_WHITE_LIST = [401]

  if (!ERROR_WHITE_LIST.includes(get(error, 'response.status', 0))) {
    Sentry.withScope((scope) => {
      scope.setTag('api_error', `api_error_${get(error, 'response.status', 0)}`)
      scope.setExtra('api_url', _.get(error, 'config.url'))
      Sentry.captureException(error)
    })
  }
}

// 人为延迟时序，直至已确定 i18n
function waitI18nInit() {
  return unit() // eslint-disable-line no-use-before-define
  function unit(counting = 0) {
    counting++
    return new Promise((resolve, reject) => {
      if (!window['is-detected-countryCode-zKJHiTMC2iGnHOwx']) {
        if (counting > 20) {
          // 至多 wait 5s
          if (getRuntimeEnv() === 'prod') {
            //  如果超过5s还未拿到countryCode且为正式环境，1、从cookies获取；2、默认ID
            window['is-detected-countryCode-zKJHiTMC2iGnHOwx'] = true
            window['i18n-countryCode-zKJHiTMC2iGnHOwx'] =
              JsCookie.get('countryCode') || 'ID'
            console.warn('超过5s,当前countryCode来自cookies或默认ID')
            resolve()
          } else {
            reject('ajax 自锁，等待语言推导，超时。放弃等待')
            if (
              location.hostname === 'localhost' ||
              /^\d+\.\d+\.\d+\.\d+$/.test(location.hostname)
            ) {
              // 本地环境提醒开发者变更逻辑
              alert(
                '代码时序错误，请在发送 ajax 请求前确定语言（提前执行完成 detectI18n 或 getBasicInfo）',
              )
            }
          }
          return
        }
        console.warn('ajax 自锁，等待 语言推导完成')
        setTimeout(() => {
          resolve(unit(counting))
        }, 250)
      } else {
        resolve()
      }
    })
  }
}

const gwDomainMap = {
  capi: 'ec-api.akulaku.com',
  oapi: 'ec-oapi.akulaku.com',
}

// 获取当前环境的网关域名
function computeGatewayInfo(countryCode, prefix) {
  const runtimeEnv = getRuntimeEnv()
  const bizEnv = getBizEnv()
  const protocol = 'https:'
  let rootDomain = gwDomainMap[prefix] ? gwDomainMap[prefix] : 'app.akulaku.com'

  const domain =
    rootDomain === 'app.akulaku.com'
      ? [
          bizEnv === 'prod' ? '' : `${bizEnv}-`,
          `${countryCode.toLowerCase()}-`,
          'app.akulaku.com',
        ].join('')
      : [bizEnv === 'prod' ? '' : `${bizEnv}-`, rootDomain].join('')

  let gatewayOrigin = `${protocol}//${domain}`
  let requestBaseUrl
  if (runtimeEnv === 'local') {
    // eslint-disable-line no-undef
    if (rootDomain !== 'app.akulaku.com') {
      requestBaseUrl = ''
    } else {
      requestBaseUrl = '/local-proxy-api'
    }
  } else {
    requestBaseUrl = gatewayOrigin
  }
  return { gatewayOrigin, requestBaseUrl }
}

// 获取设备指纹里面的参数
function getDeviceData() {
  const deviceData = storage.getSession('deviceData')

  if (deviceData) {
    const deviceType = get(deviceData, ['os'], '').toLowerCase()
    const deviceId = get(deviceData, ['device_id'], '')
    const longitude = get(deviceData, ['location', 'longitude'], '')
    const latitude = get(deviceData, ['location', 'latitude'], '')
    return {
      'device-type': deviceType,
      'device-id': deviceId,
      longitude,
      latitude,
    }
  }
  return {}
}

// 非空判断
function notEmpty(val) {
  return val !== undefined && val !== null && val !== ''
}

// 是否是pc端
function isPc() {
  return window.location.href.indexOf('/pc/') > -1
}

/**
 * 订单信息
 *  X_MERCHANT_ID X_REF_NO X_SIGN字段（分别对应 appId, refNo, sign
 * */
function getOrderData() {
  const { appId, refNo, sign, opSessionId } = URL.allParam()
  const orderData = {
    'X-MERCHANT-ID': appId,
    'X-REF-NO': refNo,
    // eslint-disable-next-line no-control-regex
    'X-SIGN': _.replace(sign, /[^\x00-\x7F]/g, ''),
  }
  if (opSessionId) {
    orderData['X-SESSION-ID'] = opSessionId
  }
  return orderData
}

// 页面类型 pc 或者 h5
const PAGE_TYPE = {
  PC: 2,
  APP: 1,
}

// 印尼的语言id countryId
const ID_LANGUAGE_ID = 123
const ID_COUNTRY_ID = 1
// 获取请求头配置
function getRequestHeaderConfig() {
  const deviceData = getDeviceData()
  const orderData = getOrderData()
  const pageType = isPc() ? PAGE_TYPE.PC : PAGE_TYPE.APP
  // 获取语言码id 默认为印尼的123
  const languageId = JsCookie.get('languageId') || ID_LANGUAGE_ID
  const xUserCountryId = JsCookie.get('countryId') || ID_COUNTRY_ID

  return {
    ...deviceData,
    ...orderData,
    'page-type': pageType,
    'language-id': languageId,
    'X-user-country-id': xUserCountryId,
  }
}

export default axios
