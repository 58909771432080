import { dynamic } from 'umi'

const wrappeedDaynamic = (loader) => {
  return dynamic({
    loader,
    loading: () => <></>,
  })
}

export const prerenderDynamics = {
  old: {
    App: wrappeedDaynamic(() =>
      import(/* webpackChunkName: 'openPay/old/app' */ '@pc/app.js'),
    ),
    Login: wrappeedDaynamic(() =>
      import(/* webpackChunkName: 'openPay/old/signin' */ '@pc/views/login'),
    ),
    OrderDetails: wrappeedDaynamic(() =>
      import(
        /* webpackChunkName: 'openPay/old/orderDetails' */ '@pc/views/orderDetails'
      ),
    ),
  },
  new: {
    App: wrappeedDaynamic(() =>
      import(/* webpackChunkName: 'openPay/app' */ '~/app.js'),
    ),
    Login: wrappeedDaynamic(() =>
      import(/* webpackChunkName: 'openPay/signin' */ '~/views/login'),
    ),

    OrderDetails: wrappeedDaynamic(() =>
      import(
        /* webpackChunkName: 'openPay/orderDetails' */ '~/views/orderDetails'
      ),
    ),

    IpVerifyResult: wrappeedDaynamic(() =>
      import(
        /* webpackChunkName: 'openPay/IpVerifyResult' */ '~/views/IpVerifyResult'
      ),
    ),
  },
}
