import performanceCollection from '@al/performance-collect'
import { getRuntimeEnv } from '@common/helpers/is'
import _ from 'lodash'
const RUNTIME_ENV = getRuntimeEnv()

const firebaseConfig =
  RUNTIME_ENV !== 'prod'
    ? {
        apiKey: 'AIzaSyBkk1nKE_vvCL3ceaUVkLP53HROVK8sSkE',
        authDomain: 'akulaku-web-test.firebaseapp.com',
        databaseURL: 'https://akulaku-web-test.firebaseio.com',
        projectId: 'akulaku-web-test',
        storageBucket: 'akulaku-web-test.appspot.com',
        messagingSenderId: '998612602448',
        appId: '1:998612602448:web:257292bafce46209dc0b55',
        measurementId: 'G-6DHN1732HG',
      }
    : {
        apiKey: 'AIzaSyBt7SLd-WLlPpHQcIBlzOhhIbwIjH5Du6g',
        authDomain: 'akulaku-web-1a00b.firebaseapp.com',
        databaseURL: 'https://akulaku-web-1a00b.firebaseio.com',
        projectId: 'akulaku-web-1a00b',
        storageBucket: 'akulaku-web-1a00b.appspot.com',
        messagingSenderId: '778102340676',
        appId: '1:778102340676:web:67818a8c8cbdc58281d011',
      }

const routeConfig = (() => {
  const routeNamePrefix = 'op'
  const obj = {
    //#region mobile端
    '/AppPayMiddle': { prometheus: 'app支付中间页' },
    '/CreateOrder': { prometheus: 'Alipay+创单中间页' },
    '/CreateOrderResult': { prometheus: 'Alipay+传单失败页' },
    '/applylimit-instruction': { prometheus: '授信引导页' },
    '/approvalResult': { prometheus: '授信审核结果页' },
    '/order-list': { prometheus: '订单列表' },
    '/orderDetails': { prometheus: '分期确认页' },
    '/paymentMethod': { prometheus: '支付方式选择' },
    '/paymentResult': { prometheus: '支付结果页' },
    '/signin': { prometheus: '登录页面' },
    '/terms/quickPay': { prometheus: '小额免密协议' },
    '/terms/serviceAgreement': { prometheus: '协议页' },
    '/CreateOrderFailed': { prometheus: '创单失败页' },
    //#endregion

    //#region pc端 部分h5页面再pc段无
    '/pc/applylimit-instruction': { prometheus: 'pc授信引导页' },
    '/pc/orderDetails': { prometheus: 'pc分期确认页' },
    '/pc/paymentMethod': { prometheus: 'pc支付方式选择' },
    '/pc/paymentResult': { prometheus: 'pc支付结果页' },
    '/pc/signin': { prometheus: 'pc登录页面' },
    '/pc/terms/serviceAgreement': { prometheus: 'pc协议页' },
    '/pc/terms/quickPay': { prometheus: 'pc小额免密协议' },
    '/pc/CreateOrderFailed': { prometheus: 'pc创单失败页' },
    //#endregion
  }

  // FIXME: 暂时不用 prometheus 那边用的少
  const prometheusEnabled = false
  _.forEach(obj, (value, key) => {
    value.name = `${routeNamePrefix}-${_.snakeCase(key)}`
    _.set(value, 'firebase.enabled', true)

    if (prometheusEnabled) {
      if (_.isString(value.prometheus)) {
        const t = value.prometheus
        value.prometheus = {
          extraInfo: {
            businessType: t,
            businessChildType: t,
          },
        }
      }
      _.set(value, 'prometheus.enabled', true)
    }
  })

  return obj
})()

performanceCollection.init({
  config: firebaseConfig,
  env: RUNTIME_ENV,
  pageMode: 'export',
  routeBase: '/open-pay', // 项目 base 路由
  shouldLog: RUNTIME_ENV !== 'prod', // 是否打印日志，根据需要填写，生产环境不打印
  shouldReport: RUNTIME_ENV === 'prod', // 是否上报数据，生产环境总是上报
  routeConfig,
})

// 重新导出，确保外部使用的时候一定调用了init
export { performanceCollection }
