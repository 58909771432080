// @ts-nocheck
import { plugin } from './plugin';
import * as Plugin_0 from '/data/data.akualku/.jenkins/workspace/test-ec-mobile-openpay/src/app.tsx';

  plugin.register({
    apply: Plugin_0,
    path: '/data/data.akualku/.jenkins/workspace/test-ec-mobile-openpay/src/app.tsx',
  });

export const __mfsu = 1;
