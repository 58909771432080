const root = global || window

// 值类型判断 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export const isUndefined = (val) => typeof val === 'undefined'

export const isNull = (val) => val === null

export const isFunction = (val) => typeof val === 'function'

export const isArray = (val) => val instanceof Array

const __error__type = [
  'Error',
  'EvalError',
  'RangeError',
  'ReferenceError',
  'SyntaxError',
  'TypeError',
  'URIError',
]
  .map((key) => root[key])
  .filter((type) => !isUndefined(type))
export const isError = (val) =>
  __error__type.some((type) => val instanceof type)

export const isObject = (val) =>
  typeof val === 'object' && !(isArray(val) || isNull(val))

export const isBoolean = (val) => typeof val === 'boolean'

export const isString = (val) => typeof val === 'string'

export const isExist = (val) => !(isUndefined(val) || isNull(val))

export const isNaN = (val) => val !== val

export const isNumber = (val) => typeof val === 'number' && !isNaN(val)
// 值类型判断 -------------------------------------------------------------

// 环境判断 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export const isAndroid = () => /(Android)/i.test(window.navigator.userAgent)

export const isAkulakuAndroid = () => {
  console.warn('此方法即将弃用，请使用 @common.akulaku.isAkulakuAndroid 代替')
  return isAndroid() && isExist(window.InstallmentJsObj)
}

export const isIOS = () =>
  /(iPhone|iPad|iPod|iOS)/i.test(window.navigator.userAgent)

export const isWKWebview = () => isIOS() && isExist(window.webkit)

export const isDesktop = () =>
  /(Win32|Win64|MacIntel|Linux x86_64)/i.test(window.navigator.platform)

export const isMobile = () => !isDesktop() && (isAndroid() || isIOS())

export const isAkulakuIOS = () => {
  console.warn('此方法即将弃用，请使用 @common.akulaku.isAkulakuIOS 代替')

  return (
    isWKWebview() &&
    isExist(window.webkit.messageHandlers) &&
    isExist(window.webkit.messageHandlers.changeLanguage)
  )
}

export const isAkulaku = () => {
  console.warn('此方法即将弃用，请使用 @common.akulaku.isAkulaku 代替')

  return isAkulakuIOS() || isAkulakuAndroid()
}

// 获取运行时环境
export function getRuntimeEnv() {
  const hostname = location.hostname
  if (
    hostname === 'localhost' ||
    hostname === '0.0.0.0' ||
    hostname === '127.0.0.1' ||
    hostname.startsWith('10.0')
  ) {
    return 'local'
  } else if (hostname.startsWith('test') || hostname.startsWith('sit')) {
    return 'test'
  } else {
    return 'prod'
  }
}

// 获取业务运行环境
export function getBizEnv() {
  const hostname = location.hostname
  if (hostname.startsWith('10.0')) {
    return 'test'
  }
  if (hostname.startsWith('sit')) {
    return 'sit'
  } else if (!/^test/.test(hostname) && /^\w+[\.\-]/.test(hostname)) {
    return 'prod'
  } else {
    return 'test'
  }
}
// 环境判断 -------------------------------------------------------------
