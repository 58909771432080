// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/data/data.akualku/.jenkins/workspace/test-ec-mobile-openpay/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "exact": false,
    "path": "/",
    "component": require('@/project/entry/index.js').default
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
