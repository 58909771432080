import './style/reset.scss'
import './style/__postcss-viewport-units__fix.scss'
import './style/__antd__fix.scss'
import './style/uno.css'

import './axiosConfig'
import './performanceReport'
import './ga'

import('@SALogger')
