import '__prefix__'
import { Route, Switch } from 'react-router-dom'
import { dynamic } from 'umi'
import MobileEntry from './mobile-entry'

const PcEntry = dynamic({
  loader: () =>
    import(
      /* webpackChunkName: 'project__pc__app.pc' */ '@/project/pc/app.pc.js'
    ),
  loading: () => null,
})

export default function Entry() {
  return (
    <Switch>
      <Route path={'/pc'} exact={false} component={PcEntry}></Route>

      <Route path={'/'} exact={false} component={MobileEntry}></Route>
    </Switch>
  )
}
