/* eslint-disable */

// 工程情况复杂，不再建立标准测速。简化处理 GA 埋点和上报
try {
  loadGa()
  performanceCollector()
} catch (err) {
  console.error(err)
  // 容错
}

// 常量，决定了下方统计 URL，hash 是否作为路径的一部分
const isHashHistory = false

// 加载 ga
function loadGa() {
  // 项目 ID
  const GA_TRACKING_ID = 'UA-67787113-9'

  // 仅生产环境统计
  const IS_GA_ENABLE = location.host === 'mall.akulaku.com' && !isInIframe()
  if (!IS_GA_ENABLE) {
    window[`ga-disable-${GA_TRACKING_ID}`] = true
  }

  // 提前初始化，方便业务侧使用自定义事件上报
  window.dataLayer = window.dataLayer || []

  function gtag() {
    dataLayer.push(arguments) // eslint-disable-line
  }

  window.gtag = gtag

  // 加载脚本
  const scriptDom = document.createElement('script')
  scriptDom.src =
    'https://www.googletagmanager.com/gtag/js?id=' + GA_TRACKING_ID
  scriptDom.async = true
  const firstScriptDom = document.getElementsByTagName('script')[0]
  firstScriptDom.parentNode.insertBefore(scriptDom, firstScriptDom)

  gtag('js', new Date())
  gtag('config', GA_TRACKING_ID, { send_page_view: false }) // 关闭自动 pv 统计，手动控制

  // 上报当前页
  gtag('config', GA_TRACKING_ID, {
    page_path: getPathname(),
    page_location: getFullPath(),
  })

  function isInIframe() {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  }
}

// 性能采集，具体行为见 wiki  http://wiki.al.com/pages/viewpage.action?pageId=8192243
function performanceCollector() {
  const isSupportsGa = !!window.gtag
  const gtag = function () {
    // 10% 采样
    if (Math.random() < 0.1) {
      window.gtag.apply(this, arguments)
    }
  }

  // 特殊，v4 依赖 readystatechange 关闭 loading，实际可交互时间几乎延迟到了 load
  let interactiveReadyTime
  const readystatechangeListener = () => {
    if (document.readyState === 'complete') {
      setTimeout(() => {
        interactiveReadyTime = Date.now()
      }, 0)
      window.removeEventListener('readystatechange', readystatechangeListener)
    }
  }
  document.addEventListener('readystatechange', readystatechangeListener)

  // 提前声明，防止异步计算后已重定向
  const pathname = getPathname()
  const fullPath = getFullPath()
  calculateInitialLoadMetrics(
    {
      pageType: 'SPA',
      priorityGetter: {
        getInteractiveReady(metrics) {
          return interactiveReadyTime - metrics.initOrigin
        },
      },
    },
    (metrics) => {
      const metricsInfo = [
        ['before_parsing', '初始化宿主至解析之前', metrics.beforeParsing],
        [
          'interactive_ready',
          '开始解析执行至交互可用',
          metrics.interactiveReady,
        ],
        ['load_complete', '开始解析执行至加载完成', metrics.loadComplete],
      ]
      console.log('SPA加载性能 ' + formatMetrics(metricsInfo))
      // 支持 ga 时上报测速数据
      if (isSupportsGa) {
        metricsInfo.forEach((info) => {
          gtag('event', 'timing_complete', {
            name: `page_load:v4:${info[0]}`,
            event_category: pathname,
            event_label: fullPath,
            value: Math.round(info[2]), // ms 传输，最终 GA 展示时除以1000，展示单位为秒
          })
        })
      }
    },
  )

  calculateRenderMetrics((type, value, e) => {
    if (type === 'input-latency') {
      const domPath = getEventSourcePath(e)
      const pathname = getPathname()
      console.warn(
        '渲染性能异常',
        `输入延迟过大: ${Math.round(value)}ms, 元素: ${domPath}`,
      )
      if (isSupportsGa) {
        gtag('event', 'input-latency', {
          event_category: pathname,
          event_label: `${pathname}|${domPath}|${
            e.target && e.target.innerText
          }`,
          value: Math.round(value), // ms
          non_interaction: true,
        })
      }
    } else if (type === 'long-task:click') {
      const domPath = getEventSourcePath(e)
      const pathname = getPathname()
      console.warn(
        '渲染性能异常',
        `交互事件耗时过长: ${Math.round(value)}ms, 元素: ${domPath}`,
      )
      if (isSupportsGa) {
        gtag('event', 'long-task:click', {
          event_category: pathname,
          event_label: `${pathname}|${domPath}|${
            e.target && e.target.innerText
          }`,
          value: Math.round(value), // ms
          non_interaction: true,
        })
      }
    }
  })
}

/* 下方来自公共代码，请勿修改 */

function getEventSourcePath(e) {
  const r = []
  try {
    e.path.every((dom) => {
      if (!dom.tagName || dom.tagName === 'BODY') {
        return false
      }
      r.push(
        dom.tagName +
          (dom.id ? `#${dom.id}` : '') +
          (dom.className ? `.${dom.className}` : ''),
      )
      return true
    })
  } catch (err) {
    // no stuff
  }
  if (!r.length) {
    return 'BODY'
  }
  r.reverse()
  return r.join('>')
}

function normalizeUrl() {
  const pathname = location.pathname.replace(/\/{2,}/g, '/') // 部分地址配置错误，出现多个 "/"
  const search = location.search.replace(/^\?/, '')
  const hash = location.hash.replace(/^#/, '')
  return {
    pathname,
    search,
    hash,
  }
}

function getPathname() {
  const url = normalizeUrl()
  if (!isHashHistory) {
    return url.pathname
  } else {
    return (
      url.pathname +
      (url.search ? '?' + url.search : '') +
      (url.hash ? '#' + url.hash : '#/')
    )
  }
}

function getFullPath() {
  const url = normalizeUrl()
  if (!isHashHistory) {
    return url.pathname + (url.search ? '?' + url.search : '')
  } else {
    return (
      url.pathname +
      (url.search ? '?' + url.search : '') +
      (url.hash ? '#' + url.hash : '#/')
    )
  }
}

function formatMetrics(metrics) {
  const format = (m) => {
    const timeString = m[2] ? Number(m[2] / 1000).toFixed(2) + 's' : ''
    return `${m[0]}/${m[1]}: ${timeString || '?'}`
  }
  return metrics.map(format).join(', ')
}

/* 下方来自公共代码，请勿修改 */

// 计算初始页面加载性能
export function calculateInitialLoadMetrics(
  { pageType = 'SPA', htmlSpeedPoint = {}, priorityGetter = {} },
  callback,
) {
  // 声明指标
  const metrics = {
    timeOrigin: undefined,
    initOrigin: undefined,
    beforeParsing: undefined,
    contentVisible: undefined,
    interactiveReady: undefined,
    loadComplete: undefined,
  }

  // 计算加载时间
  let eventInteractiveReadyTime
  onceEvent('__al_ex_event_app_mounted_w6Q99aok', () => {
    eventInteractiveReadyTime = Date.now()
  })
  onceEvent('load', () => {
    const isSSR = pageType === 'SSR'
    const isSPA = pageType === 'SPA'
    const isSupportsTiming = window.performance && performance.now
    const loadEventCallTime = Date.now()
    const loadEventCallDiffNow = isSupportsTiming
      ? performance.now()
      : Date.now()

    setTimeout(
      () => {
        // 所有行为的基准时间，浏览器准备打开指定页面
        tryExecTasks(
          () => !isNumber(metrics.timeOrigin),
          () =>
            priorityGetter.getTimeOrigin &&
            (metrics.timeOrigin = priorityGetter.getTimeOrigin(metrics)),
          () =>
            isSupportsTiming && (metrics.timeOrigin = performance.timeOrigin),
        )

        // 接收到服务端响应，开始解析执行 HTML
        // 优先使用 performance、备选 html
        tryExecTasks(
          () => !isNumber(metrics.initOrigin),
          () =>
            priorityGetter.getInitOrigin &&
            (metrics.initOrigin = priorityGetter.getInitOrigin(metrics)),
          () =>
            isSupportsTiming &&
            (metrics.initOrigin = performance.timing.domLoading),
          () =>
            htmlSpeedPoint.getInitOrigin &&
            (metrics.initOrigin = htmlSpeedPoint.getInitOrigin(metrics)),
        )

        // 开始解析执行 HTML 前花费的时间
        tryExecTasks(
          () => !isNumber(metrics.beforeParsing),
          () =>
            priorityGetter.getBeforeParsing &&
            (metrics.beforeParsing = priorityGetter.getBeforeParsing(metrics)),
          () =>
            (metrics.beforeParsing = metrics.initOrigin - metrics.timeOrigin),
          () =>
            htmlSpeedPoint.getInitTimeBefore &&
            (metrics.beforeParsing = htmlSpeedPoint.getInitTimeBefore() - 100),
        )

        // 开始解析执行至内容可见
        // 优先 html、备选 performance
        tryExecTasks(
          () => !isNumber(metrics.contentVisible),
          () =>
            priorityGetter.getContentVisible &&
            (metrics.contentVisible =
              priorityGetter.getContentVisible(metrics)),
          () =>
            htmlSpeedPoint.getContentVisible &&
            (metrics.contentVisible =
              htmlSpeedPoint.getContentVisible(metrics)),
          () => {
            if (isSSR && isSupportsTiming) {
              const { domLoading, domInteractive } = performance.timing
              metrics.contentVisible =
                domLoading +
                (domInteractive - domLoading) * 0.85 -
                metrics.initOrigin
            }
          },
        )

        // 开始解析执行至可交互
        // 优先 事件上报、html、备选 performance
        tryExecTasks(
          () => !isNumber(metrics.interactiveReady),
          () =>
            priorityGetter.getInteractiveReady &&
            (metrics.interactiveReady =
              priorityGetter.getInteractiveReady(metrics)),
          // 等待外部事件，超时时忽略不上报
          () =>
            (metrics.interactiveReady =
              eventInteractiveReadyTime - metrics.initOrigin),
        )

        // 开始解析执行至加载完成
        // 优先 now，其次 time
        tryExecTasks(
          () => !isNumber(metrics.loadComplete),
          () =>
            priorityGetter.getLoadComplete &&
            (metrics.loadComplete = priorityGetter.getLoadComplete(metrics)),
          () => (metrics.loadComplete = loadEventCallDiffNow),
          () => (metrics.loadComplete = loadEventCallTime - metrics.initOrigin),
        )

        // 清理可能的异常值
        Object.keys(metrics).forEach((key) => {
          if (!isNumber(metrics[key])) {
            metrics[key] = undefined
          }
        })

        callback(metrics)
      },
      isSPA ? 10000 : 3000,
    )
  })
}

// 计算路由切换页面加载性能
function calculateRouteLoadMetrics(callback) {
  let routeList = []
  const isSupportsTiming = window.performance && performance.now
  const getNow = () => (isSupportsTiming ? performance.now() : Date.now())
  window.addEventListener('__al_ex_event_route_page_start_w6Q99aok', (e) => {
    const pathname = e.detail && e.detail.pathname
    if (!pathname) {
      return
    }
    routeList.push([pathname, getNow()])
  })
  window.addEventListener('__al_ex_event_app_did_update_w6Q99aok', (e) => {
    const pathname = e.detail && e.detail.pathname
    if (!pathname) {
      return
    }
    const completeNow = getNow()
    setTimeout(() => {
      for (let i = routeList.length - 1; i >= 0; i--) {
        const info = routeList[i]
        if (info[0] === pathname) {
          const startNow = info[1]
          const diff = completeNow - startNow
          callback({
            interactiveReady: diff,
          })
          return
        }
      }
      console.error('BUG', '未获取路由切换后的上报点') // eslint-disable-line
    }, 500)
  })
}

// 计算渲染/交互性能
function calculateRenderMetrics(callback) {
  const isSupportsPerformance = !!window.performance && performance.timing
  const isSupportsTiming = window.performance && performance.now
  const getNow = () => (isSupportsTiming ? performance.now() : Date.now())
  if (isSupportsPerformance) {
    // 最近发生了 long-task 时，将导致批量的行为延迟，产生无意义上报
    // 提供临时跳过机制，一旦发现 long-task，放行已堆积的尚未触发的事件
    let ignoreCollectViaLongTask

    // 输入延迟性能
    window.addEventListener(
      'ontouchstart' in window ? 'touchstart' : 'click',
      function (e) {
        if (ignoreCollectViaLongTask) {
          return
        }
        const diffTime = getNow() - e.timeStamp
        if (isNumber(diffTime) && diffTime > 100) {
          callback('input-latency', diffTime, e)
        }
      },
      true,
    )

    // 长任务
    // 仅检查部分，在交互时运行的重任务
    window.addEventListener('click', function (e) {
      if (ignoreCollectViaLongTask) {
        return
      }
      const diffTime = getNow() - e.timeStamp
      if (isNumber(diffTime) && diffTime > 50) {
        // 发现长任务，之后 1s 暂停检测，防止被堆积的任务批量上报
        ignoreCollectViaLongTask = true
        setTimeout(() => {
          ignoreCollectViaLongTask = false
        }, 1000)
        callback('long-task:click', diffTime, e)
      }
    })
  }
}

function onceEvent(eventName, callback) {
  const eventListener = function () {
    window.removeEventListener(eventName, eventListener)
    return callback.apply(this, arguments)
  }
  window.addEventListener(eventName, eventListener)
}

function tryExecTasks(condition, ...tasks) {
  for (let i = 0; i < tasks.length; i++) {
    if (!condition()) {
      break
    }
    try {
      tasks[i]()
    } catch (err) {
      // 容错
    }
  }
}

function isNumber(v) {
  return !Number.isNaN(Number(v)) && typeof v !== 'boolean'
}
