import { prerenderDynamics } from '@/__prefix__/prerenders'

export function render(oldRender: () => void) {
  // @ts-expect-error 不管怎么样，客户端渲染开始的时候都应该禁用prerender
  window.__prerender__enabled__ = false
  // @ts-expect-error prerender 之后会添加 g_useSSR 赋值代码
  if (!window.g_useSSR) {
    return oldRender()
  }

  const isPc = window.location.href.includes('/pc/')
  if (isPc) {
    return oldRender()
  }

  const isOrderDetails = window.location.href.includes('/orderDetails')
  //@ts-expect-error 在 index.html 中赋值
  const config = window.__use_new_ui__
    ? prerenderDynamics.new
    : prerenderDynamics.old
  ;(async () => {
    const appRes = await config.App.preload()
    config.App = appRes.default || appRes

    if (isOrderDetails) {
      const orderDetailRes = await config.OrderDetails.preload()
      config.OrderDetails = orderDetailRes.default || orderDetailRes
    } else {
      const loginRes = await config.Login.preload()
      config.Login = loginRes.default || loginRes
    }
    oldRender()
    console.log('prerenderDynamics', prerenderDynamics)
  })()
}
